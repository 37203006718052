import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Markdown from "./Markdown";
import moment from "moment";

import Link from "./localizedLink";

import * as Styles from "../styles/blogLayout.module.scss";

const BlogTile = ({ blogNode }) => {
  return (
    <article key={blogNode.id} class={Styles.blogTile}>
      <Link to={`/blog/${blogNode.slug}`} locale={blogNode.frontmatter.locale}>
        <div class={Styles.blogTileInner}>
          <div className={Styles.heroImageBox}>
            <div className={Styles.heroImage}>
              <GatsbyImage
                image={getImage(blogNode.frontmatter.hero_image)}
                imgStyle={{ objectFit: "fill" }}
              />
            </div>
            <div className={Styles.eventDate}>
              {blogNode.frontmatter.eventDate
                ? new moment(blogNode.frontmatter.eventDate).format(
                    "DD.MM.YYYY"
                  )
                : ""}
            </div>
          </div>{" "}
          <h2>{blogNode.frontmatter.title}</h2>
          <p class={Styles.blogDate}>
            {blogNode.frontmatter.date}
            {blogNode.frontmatter.author ? (
              <>
                , <Markdown>{blogNode.frontmatter.author}</Markdown>
              </>
            ) : (
              ""
            )}
          </p>
          <p class={Styles.blogExcerpt}>{blogNode.excerpt}</p>
        </div>
      </Link>
    </article>
  );
};
export default BlogTile;
