import * as React from 'react'
import Link from './localizedLink'
import { Tag } from "@carbon/icons-react"

import * as Styles from '../styles/categories.module.scss'

const CategoryTileSmall = ({ category, 
                             locale }) => {
  
  return (

    <div class={Styles.categoryTileSmall}>      
      <Link to={category.data.Slug.toLowerCase()} >
        
        <div class="columns">
            <Tag />
          <p><b>{category.data.Name}</b></p>    
        </div>
      </Link>
    </div>
  )
}
export default CategoryTileSmall 