import { graphql } from 'gatsby'
import * as React from 'react'

import i18next from '../i18n.js'

import BlogIndexTemplate from '../components/pageTemplates/blogIndexTemplate'

const BlogPage = ({ data, pageContext }) => {
  i18next.changeLanguage('de')
  var  {t} = i18next

  return (
    <BlogIndexTemplate title={t('pageTitle')}
                       categoryListTitle={t('blogCategories')} 
                      pageContext={pageContext} 
                      data={data} 
                      locale="de" />     
  )
} 

export const query = graphql`
query blogDe {
  allMdx(
    filter: {frontmatter: { locale: {eq: "de"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    nodes {
      frontmatter {
        draft
        date(formatString: "D. MMMM YYYY", locale: "DE")
        title
        locale
        author
        eventDate
        noindex
        hero_image {
          childImageSharp {
            gatsbyImageData(width: 280, 
                            height: 140,
                            placeholder: TRACED_SVG,
                            transformOptions:{cropFocus:CENTER,
                                              fit: COVER} )
          }
        }
      }
      id
      slug
      excerpt
    }
  }
  allAirtableCategories(filter: {data: {Locale: {eq: "de"}}}) {
    nodes {
      data {
        Name
        Slug
        Locale
      }
    }
  }
}
`
/**  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  } */
export default BlogPage