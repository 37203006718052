import * as React from 'react'

import BlogTile      from '../blogTile'
import CategoryList  from '../categoryList'
import SidebarLayout from '../sidebarLayout'

import * as Styles from '../../styles/blogLayout.module.scss'

const BlogIndexTemplate = ({ data, 
                            pageContext,
                            title ,
                            locale, 
                            categoryListTitle}) => {

    var nodes = process.env.DRAFT?data.allMdx.nodes
                                 :data.allMdx.nodes.filter( node => node.frontmatter.draft !== true );
    var translationContext = { locale: locale,
                               defaultMenu: true };
  return (
    <SidebarLayout title={title}
            pageContext={pageContext} 
            hideTitle={true}
            locale={locale}
            translationContext={translationContext}
            main={(
              <div class="main-padding">
                <h1>{title}</h1>
      <div class={Styles.blogGrid}>
        {
          nodes.filter( node => node.frontmatter.noindex !== 'true').map(node => (
            <BlogTile blogNode={node} />
          ))
        }

      </div></div>
    )} 
    
    sidebar={(
            <CategoryList nodes={data.allAirtableCategories.nodes}
                          locale={locale}
                          title={categoryListTitle}  /> 
    )}
    />
  )
}
export default BlogIndexTemplate