import * as React from 'react'

import CategoryTileSmall from './categoryTileSmall'

import * as Styles from '../styles/categories.module.scss'

const CategoryList = ({ nodes, 
                        title,
                        locale }) => {
  return (

    <div class={Styles.categoryList}>
      <h3>{title}</h3>
                 {nodes.filter((a) => a !== null).map(category => (
            <CategoryTileSmall category={category}
                               locale={locale} /> 
          ))
        }      

</div>

  )
} 

export default CategoryList